import { components } from '@/schema'
import * as Yup from 'yup'
import { recipientSimpleValidator, RecipientPublic } from './recipients'

export type RList = components['schemas']['RecipientList']

export type RListSimple = components['schemas']['RecipientListSimple']

export type RListPublic = components['schemas']['RecipientListPublic']

export type RListPublicEmbed = Omit<RListPublic, 'recipients'> & {
    recipients: Array<RecipientPublic>
}

export type RListWrite = Omit<RList, 'id' | 'updated_date' | 'update_status'>

export const rlistValidationBase = {
    id: Yup.string().optional(),
    name: Yup.string().required(),
    rlist_type: Yup.string()
        .oneOf(['deputes_fr', 'mairies_fr'])
        .nullable()
        .optional(),
    tags: Yup.array()
        .of(
            Yup.object().shape({
                slug: Yup.string().required(),
                name: Yup.string().required(),
            })
        )
        .default([]),
}

export const rlistSimpleValidator: Yup.ObjectSchema<RListSimple> =
    Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
        recipients: Yup.array().of(recipientSimpleValidator).required(),
        geolocation_available: Yup.boolean().default(false),
        tags: Yup.array()
            .of(
                Yup.object().shape({
                    slug: Yup.string().required(),
                    name: Yup.string().required(),
                })
            )
            .default([]),
    })
