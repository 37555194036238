<template>
    <div>
        <ListPage
            :is-busy="crudStore.isBusy"
            title-t-key="backoffice.recipients.recipients"
            description-t-key="backoffice.recipients.description"
            create-action-t-key="backoffice.actions.create"
            no-element-message-t-key="backoffice.recipients.no_recipient_yet"
            create-element-message-t-key="backoffice.recipients.create_your_first_recipient"
            create-route-name="recipient_create"
            :is-list-data-empty="crudStore.isListDataEmpty"
            route-name="recipients_list"
            :list-meta="crudStore.listMeta"
            :list-filters="crudStore.listFilters"
            downloadTKey="backoffice.actions.download"
            @download-clicked="extraStore.downloadListAsCsv"
        >
            <div
                v-if="!crudStore.isListBusy && crudStore.listMeta"
                class="container py-2 w-full"
            >
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-white">
                        <tr class="rounded-t-xl">
                            <ColumnHeader
                                v-for="column in COLUMNS"
                                :key="column"
                                :column="column"
                                :sort="{
                                    sortOrder: 'asc',
                                    sortByColumn: column,
                                }"
                            >
                                {{
                                    t(`backoffice.person.${camelCase(column)}`)
                                }}
                            </ColumnHeader>
                            <th class="px-8 py-2"></th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <TableRow
                            v-for="recipient in crudStore.listData"
                            :key="recipient.email"
                            :recipient="recipient"
                        />
                    </tbody>
                </table>
            </div>
        </ListPage>
    </div>
</template>

<script setup lang="ts">
import camelCase from 'camelcase'
import { onBeforeMountOrPropChange } from '@/utils/components'
import ColumnHeader from '@/AppBackoffice/components/ListTable/ColumnHeader.vue'
import TableRow from './TableRow.vue'
import {
    useRecipientsBackofficeCrudStore,
    useRecipientsExtraStore,
} from './stores'
import { Recipient } from '@/models/recipients'
import { useI18n } from 'vue-i18n'
import ListPage from '@/AppBackoffice/components/ListPage.vue'

interface Props {
    page: number
    filter?: string
}

const COLUMNS: Array<keyof Recipient> = ['last_name', 'first_name', 'email']

const props = defineProps<Props>()
const { t } = useI18n()
const crudStore = useRecipientsBackofficeCrudStore()
const extraStore = useRecipientsExtraStore()

const onListConfigUpdated = () => {
    crudStore.runList({
        page: props.page,
        filter: props.filter,
    })
}

onBeforeMountOrPropChange(
    [() => props.page, () => props.filter],
    onListConfigUpdated
)
</script>
