<template>
    <div>
        <ListPage
            :is-busy="crudStore.isBusy"
            title-t-key="backoffice.rlists.rlists"
            description-t-key="backoffice.rlists.description"
            create-action-t-key="backoffice.actions.create"
            no-element-message-t-key="backoffice.rlists.no_rlist_yet"
            create-element-message-t-key="backoffice.rlists.create_your_first_rlist"
            create-route-name="rlist_create"
            :is-list-data-empty="crudStore.isListDataEmpty"
            route-name="rlists_list"
            :list-meta="crudStore.listMeta"
            :list-filters="crudStore.listFilters"
        >
            <div
                class="container py-2 w-full"
                v-for="rlist in crudStore.listData"
                :key="rlist.id"
            >
                <div class="bg-white py-2 rounded-lg shadow-sm">
                    <div class="overflow-x-auto">
                        <div class="flex justify-between items-center p-4">
                            <div>
                                <h2 class="text-xl font-bold text-gray-800">
                                    {{ rlist.name }}
                                </h2>
                                <p class="text-gray-500">
                                    {{
                                        t(
                                            'backoffice.rlists.recipientsCount',
                                            rlist.recipients
                                                ? rlist.recipients.length
                                                : 0
                                        )
                                    }}
                                </p>
                                <div>
                                    <span
                                        v-for="tag in rlist.tags"
                                        :key="tag.slug"
                                        class="inline-block bg-gray-200 rounded-full px-3 py-1 mt-2 text-sm font-semibold text-gray-700 mr-2 cursor-pointer"
                                        @click="onTagFiler(tag.name!)"
                                    >
                                        {{ tag.name }}
                                    </span>
                                </div>
                            </div>

                            <div class="flex items-center space-x-4">
                                <div v-if="rlist.rlist_type">
                                    <button
                                        v-if="
                                            [
                                                null,
                                                'SUCCESS',
                                                'FAILURE',
                                            ].includes(rlist.update_status)
                                        "
                                        class="text-success"
                                        @click="updateList(rlist.id)"
                                    >
                                        <i
                                            class="fi fi-rs-refresh w-3 h-3 mr-1 text-xs"
                                        ></i>
                                        {{ t('backoffice.actions.update') }}
                                    </button>
                                    <span v-else>
                                        {{ rlist.update_status }}
                                    </span>
                                </div>

                                <button
                                    @click="
                                        extraStore.downloadListAsCsv(
                                            rlist.id,
                                            rlist.name
                                        )
                                    "
                                >
                                    <i
                                        class="fi fi-rs-download w-3 h-3 mr-1 text-xs"
                                    ></i>
                                    {{ t('backoffice.actions.download') }}
                                </button>
                                <RouterLink
                                    :to="{
                                        name: 'rlist_update_inPlace',
                                        params: { rlistId: rlist.id },
                                    }"
                                    class="flex items-center text-primary hover:text-blue-700 text-sm"
                                >
                                    <i
                                        class="fi fi-rs-pencil mr-2 block translate-y-0.5"
                                    ></i
                                    >{{ t('backoffice.actions.edit') }}
                                </RouterLink>
                                <RouterLink
                                    :to="{
                                        name: 'rlist_delete_inPlace',
                                        params: { rlistId: rlist.id },
                                    }"
                                    class="flex items-center text-red-600 hover:text-red-700 text-sm"
                                >
                                    <i
                                        class="fi fi-rs-trash mr-2 block translate-y-0.5"
                                    ></i
                                    >{{ t('backoffice.actions.delete') }}
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ListPage>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMountOrPropChange } from '@/utils/components'
import { RouterLink } from 'vue-router'
import { useRListsBackofficeCrudStore } from './stores'
import { useI18n } from 'vue-i18n'
import { useRecipientsExtraStore } from '../recipients/stores'
import ListPage from '@/AppBackoffice/components/ListPage.vue'
import router from '@/router'
import { runListUpdate } from './requests'

const extraStore = useRecipientsExtraStore()

interface Props {
    page: number
    filter?: string
}

const props = defineProps<Props>()
const crudStore = useRListsBackofficeCrudStore()
const { t } = useI18n()

const onListConfigUpdated = () => {
    crudStore.runList({
        page: props.page,
        filter: props.filter,
    })
}

const updateList = async (listId: string) => {
    const response = await runListUpdate(listId)
    if (response.status === 200) {
        onListConfigUpdated()
    }
}

onBeforeMountOrPropChange(
    [() => props.page, () => props.filter],
    onListConfigUpdated
)

const onTagFiler = (tag: string) => {
    router.push({
        name: 'rlists_list',
        query: {
            filter: tag,
            page: 1,
        },
    })
}
</script>
