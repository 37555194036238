<template>
    <form @submit.prevent="onSubmit" class="mt-4">
        <div class="mb-4 w-1/2">
            <AVInput
                :label="t('backoffice.organizations.name_field')"
                v-bind="nameField.props.value"
                v-on="nameField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
            />
        </div>
        <div class="mb-4">
            <AVCheckBox
                :label="t('backoffice.organizations.isTrackingEmails')"
                v-bind="isTrackingEmailsField.props.value"
                v-on="isTrackingEmailsField.listeners.value"
            />
        </div>
        <h3 class="text-l font-bold mb-3">
            {{ t('backoffice.organizations.LLMSection') }}
        </h3>
        <div class="mb-4 w-1/2">
            <AVSelect
                :label="t('backoffice.organizations.llm_provider_field')"
                v-bind="llmProviderField.props.value"
                v-on="llmProviderField.listeners.value"
                :options="LlmProviders"
                :placeholder="t('backoffice.organizations.llm_provider_field')"
                @change="setDefaultModel"
            />
        </div>
        <div class="mb-4 w-1/2">
            <AVSelect
                :label="t('backoffice.organizations.llm_model_field')"
                v-bind="llmModelField.props.value"
                v-on="llmModelField.listeners.value"
                :options="
                    llmProviderField.props.value.modelValue
                        ? LlmModels[
                              llmProviderField.props.value
                                  .modelValue as LlmProviderType
                          ]
                        : []
                "
                :placeholder="t('backoffice.organizations.llm_model_field')"
            />
        </div>
        <div
            class="mb-4 w-1/2"
            v-if="llmProviderField.props.value.modelValue == 'openai'"
        >
            <AVInput
                :label="t('backoffice.organizations.openai_api_key_field')"
                v-bind="openaiApiKeyField.props.value"
                v-on="openaiApiKeyField.listeners.value"
                :placeholder="
                    t('backoffice.organizations.openaiApiKeyPlaceholder')
                "
            />
        </div>
        <div
            class="mb-4 w-1/2"
            v-if="llmProviderField.props.value.modelValue == 'mistral'"
        >
            <AVInput
                :label="t('backoffice.organizations.mistral_api_key_field')"
                v-bind="mistralApiKeyField.props.value"
                v-on="mistralApiKeyField.listeners.value"
                :placeholder="
                    t('backoffice.organizations.mistralApiKeyPlaceholder')
                "
            />
        </div>
        <div
            class="mb-4 w-1/2"
            v-if="llmProviderField.props.value.modelValue == 'anthropic'"
        >
            <AVInput
                :label="t('backoffice.organizations.anthropic_api_key_field')"
                v-bind="anthropicApiKeyField.props.value"
                v-on="anthropicApiKeyField.listeners.value"
                :placeholder="
                    t('backoffice.organizations.anthropicApiKeyPlaceholder')
                "
            />
        </div>
        <div
            class="mb-4 w-1/2"
            v-if="llmProviderField.props.value.modelValue == 'gemini'"
        >
            <AVInput
                :label="t('backoffice.organizations.gemini_api_key_field')"
                v-bind="geminiApiKeyField.props.value"
                v-on="geminiApiKeyField.listeners.value"
                :placeholder="
                    t('backoffice.organizations.geminiApiKeyPlaceholder')
                "
            />
        </div>
        <div
            class="mb-4 w-1/2"
            v-if="llmProviderField.props.value.modelValue == 'deepseek'"
        >
            <AVInput
                :label="t('backoffice.organizations.deepseek_api_key_field')"
                v-bind="deepseekApiKeyField.props.value"
                v-on="deepseekApiKeyField.listeners.value"
                :placeholder="
                    t('backoffice.organizations.deepseekApiKeyPlaceholder')
                "
            />
        </div>

        <h3 class="text-l font-bold mb-3">
            {{ t('backoffice.organizations.DesignSection') }}
        </h3>
        <div class="mb-4 w-full md:w-40">
            <AVInput
                :label="'Primary color'"
                v-bind="colorPrimaryField.props.value"
                v-on="colorPrimaryField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
                type="color"
            />
        </div>

        <div class="mb-4 w-full md:w-40">
            <AVInput
                :label="'Secondary color'"
                v-bind="colorSecondaryField.props.value"
                v-on="colorSecondaryField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
                type="color"
            />
        </div>

        <div class="mb-4 w-full md:w-40">
            <AVInput
                :label="'Accent color'"
                v-bind="colorAccentField.props.value"
                v-on="colorAccentField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
                type="color"
            />
        </div>
        <div class="mb-4 w-full md:w-1/2">
            <AVFileDropZone
                name="logo_organization"
                :label="t('backoffice.organizations.logo')"
                :fileTypes="['svg', 'png', 'jpg']"
                :currentFileUrl="
                    myOrganizationStore.myOrganization?.logo || undefined
                "
                v-model="newLogo"
                :isImageFile="true"
                @clear="clearLogo"
            />
        </div>
        <AVButton type="submit">
            {{ t(submitTKey) }}
        </AVButton>
    </form>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import AVInput from '@/components/forms/AVInput.vue'
import AVCheckBox from '@/components/forms/AVCheckBox.vue'
import { useMyOrganizationStore } from './stores'
import { computed, DeepReadonly, ref } from 'vue'
import AVButton from '@/components/forms/AVButton.vue'
import { organizationValidaton } from './validation'
import { useForm } from 'vee-validate'
import { makeUseField } from '@/utils/forms'
import {
    OrganizationWrite,
    Organization,
    LlmProviderType,
} from '@/models/organizations'
import { useI18n } from 'vue-i18n'
import { TKey } from '@/i18n'
import { ValidationErrors } from '@/utils/validation-errors'
import { onBeforeMountOrPropChange } from '@/utils/components'
import AVFileDropZone from '@/components/forms/AVFileDropZone.vue'
import AVSelect from '@/components/forms/AVSelect.vue'
import { LlmProviders, LlmModels } from '@/models/organizations'

interface Props {
    organization?: Organization
    submitTKey: TKey
    validationErrors: DeepReadonly<ValidationErrors<OrganizationWrite>> | null
}

interface Emits {
    (event: 'submit', organization: OrganizationWrite): void
    (event: 'cancel'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const validationSchema = computed(() => toTypedSchema(organizationValidaton))

const myOrganizationStore = useMyOrganizationStore()

const { validate, setErrors } = useForm({
    validationSchema,
    initialValues: myOrganizationStore.myOrganization || {},
})

const setValidationErrors = () => {
    if (props.validationErrors) {
        setErrors(props.validationErrors)
    }
}

const newLogo = ref<File | null>(null)
const clearLogo = () => {
    newLogo.value = new File([], '')
}

const setDefaultModel = () => {
    llmModelField.value.value =
        LlmModels[
            llmProviderField.props.value.modelValue as LlmProviderType
        ][0].value
}

const useOrganizationField = makeUseField<OrganizationWrite>()
const nameField = useOrganizationField('name')
const openaiApiKeyField = useOrganizationField('openai_api_key')
const mistralApiKeyField = useOrganizationField('mistral_api_key')
const anthropicApiKeyField = useOrganizationField('anthropic_api_key')
const geminiApiKeyField = useOrganizationField('gemini_api_key')
const deepseekApiKeyField = useOrganizationField('deepseek_api_key')
const isTrackingEmailsField = useOrganizationField('is_tracking_sent_emails')
const colorPrimaryField = useOrganizationField('color_primary')
const colorSecondaryField = useOrganizationField('color_secondary')
const colorAccentField = useOrganizationField('color_accent')
const llmProviderField = useOrganizationField('llm_provider')
const llmModelField = useOrganizationField('llm_model')

onBeforeMountOrPropChange(() => props.validationErrors, setValidationErrors)

const onSubmit = async () => {
    const { valid: isValid, values } = await validate()
    if (isValid) {
        if (newLogo.value instanceof File) {
            await myOrganizationStore.updateLogo(newLogo.value)
        }
        emit('submit', values!)
    }
}
</script>
