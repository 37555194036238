<template>
    <div>
        <LoaderWithOverlay v-if="statsStore.isBusy" />
        <div class="container mx-auto px-4 py-8">
            <ListHeader
                titleTKey="backoffice.campaigns.analytics.title"
                descriptionTKey="backoffice.campaigns.analytics.description"
            />
            <div
                v-if="tableData && statsStore.meta"
                class="container py-2 w-full"
            >
                <div
                    class="container flex flex-col md:flex-row justify-between py-4 items-end"
                >
                    <form
                        @submit.prevent=""
                        class="grid grid-col-1 md:grid-cols-4 gap-4 items-end"
                    >
                        <AVDateInput
                            name="dateStart"
                            label="From"
                            v-model="startDate"
                            @change="onUpdateRangeType"
                        />
                        <AVDateInput
                            name="dateFrom"
                            label="To"
                            v-model="endDate"
                            @change="onUpdateRangeType"
                        />
                        <AVSelect
                            name="rangeType"
                            v-model="rangeType"
                            :options="rangeTypeOptions"
                            label="Range"
                            @change="onUpdateDates"
                        />
                    </form>
                    <PaginationNavigator
                        :list-filter="statsStore.filters"
                        :listMeta="statsStore.meta"
                        @go-to-page="goToPage"
                    />
                </div>
                <ListTable
                    :columns="columnDefinitions"
                    :rows="tableData"
                    @rowClicked="onRowClicked"
                />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import ListTable from '@/AppBackoffice/components/ListTable/ListTable.vue'
import {
    ColumnDefinition,
    RowDefinition,
    RouteDefinition,
} from '@/AppBackoffice/components/ListTable/types'
import { useCampaignStatsListStore, useCampaignExtraStore } from './stores'
import { computed, ref } from 'vue'
import AVDateInput from '@/components/forms/AVDateInput.vue'
import AVSelect from '@/components/forms/AVSelect.vue'
import { onBeforeMount } from 'vue'
import { CampaignStatsListItem, InterpellationMode } from '@/models/campaigns'
import ListHeader from '../components/ListHeader.vue'
import { INTERPELLATION_MODES_ICONS } from './constants'
import mapValues from 'lodash/mapValues'
import { GeneratedContentListParams } from '../generated-contents/requests'
import { storeToRefs } from 'pinia'
import { QueryParams } from '@/types'
import PaginationNavigator from '@/AppBackoffice/components/PaginationNavigator.vue'

const rangeTypeOptions = ref([
    { value: '7day', label: 'Last 7 Days' },
    { value: 'all-time', label: 'All Time' },
    { value: 'custom', label: 'Custom', disabled: true },
])

const rangeType = ref('7day')

const statsStore = useCampaignStatsListStore()
const startDate = ref<Date | undefined>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
) // 7 days ago
const endDate = ref<Date | undefined>(new Date())

const onUpdateRangeType = () => {
    rangeType.value = 'custom'
    loadStats()
}

const onUpdateDates = () => {
    if (rangeType.value === '7day') {
        startDate.value = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        endDate.value = new Date()
    } else if (rangeType.value === 'all-time') {
        startDate.value = undefined
        endDate.value = undefined
    }
    loadStats()
}

const columnDefinitions = computed<
    Array<ColumnDefinition<CampaignStatsListItem>>
>(() => [
    { name: 'campaign_name', tKey: 'backoffice.campaigns.name' },
    { name: 'generated_email_number', icon: INTERPELLATION_MODES_ICONS.email },
    {
        name: 'generated_facebook_number',
        icon: INTERPELLATION_MODES_ICONS.facebook,
    },
    {
        name: 'generated_instagram_number',
        icon: INTERPELLATION_MODES_ICONS.instagram,
    },
    {
        name: 'generated_twitter_number',
        icon: INTERPELLATION_MODES_ICONS.twitter,
    },
    {
        name: 'generated_review_number',
        icon: INTERPELLATION_MODES_ICONS.review,
    },
    {
        name: 'generated_total_number',
        tKey: 'backoffice.campaigns.analytics.generatedTotal',
    },
])

const tableData = computed<Array<RowDefinition<CampaignStatsListItem>> | null>(
    () =>
        statsStore.data
            ? statsStore.data.reduce<
                  Array<RowDefinition<CampaignStatsListItem>>
              >((tableData, stats) => {
                  const _buildRoute = (
                      interpellationMode: InterpellationMode | null
                  ): RouteDefinition => {
                      const query: GeneratedContentListParams = {
                          ...(interpellationMode
                              ? { interpellation_mode: interpellationMode }
                              : {}),
                          campaign_id: stats.id,
                          created_start: startDate.value,
                          created_end: endDate.value,
                          page: 1,
                      }
                      return {
                          name: 'generated_contents',
                          query: query as QueryParams<
                              keyof GeneratedContentListParams
                          >,
                      }
                  }

                  const _buildCells = (
                      stats: CampaignStatsListItem,
                      hasRoute: boolean
                  ): RowDefinition<CampaignStatsListItem>['cells'] => ({
                      ...mapValues(stats, (value) => ({ value })),
                      generated_email_number: {
                          value:
                              `${stats.generated_email_number}` +
                              (stats.sent_email_number
                                  ? ` (${stats.sent_email_number})`
                                  : ''),
                          route: hasRoute ? _buildRoute('email') : undefined,
                      },
                      generated_facebook_number: {
                          value:
                              `${stats.generated_facebook_number}` +
                              (stats.sent_facebook_number
                                  ? ` (${stats.sent_facebook_number})`
                                  : ''),
                          route: hasRoute ? _buildRoute('facebook') : undefined,
                      },
                      generated_twitter_number: {
                          value:
                              `${stats.generated_twitter_number}` +
                              (stats.sent_twitter_number
                                  ? ` (${stats.sent_twitter_number})`
                                  : ''),
                          route: hasRoute ? _buildRoute('twitter') : undefined,
                      },
                      generated_instagram_number: {
                          value:
                              `${stats.generated_instagram_number}` +
                              (stats.sent_instagram_number
                                  ? ` (${stats.sent_instagram_number})`
                                  : ''),
                          route: hasRoute
                              ? _buildRoute('instagram')
                              : undefined,
                      },
                      generated_total_number: {
                          value: `${stats.generated_total_number}`,
                          route: hasRoute ? _buildRoute(null) : undefined,
                      },
                  })

                  tableData.push({
                      cells: _buildCells(stats, true),
                      expandable: true,
                  })

                  if (
                      expandedRowCampaignId.value === stats.id &&
                      campaignInterpellationsStats.value
                  ) {
                      tableData = tableData.concat(
                          campaignInterpellationsStats.value!.map((stats) => ({
                              cells: _buildCells(
                                  {
                                      ...stats,
                                      campaign_name: stats.interpellation_name,
                                  },
                                  false
                              ),
                              expandable: false,
                              rowClass: 'bg-gray-100',
                          }))
                      )
                  }
                  return tableData
              }, [])
            : null
)

const page = ref<number>(1)

const goToPage = (pageTarget: number) => {
    page.value = pageTarget
    loadStats()
}

const loadStats = () => {
    statsStore.runList({
        created_start: startDate.value,
        created_end: endDate.value,
        page: page.value,
    })
}

// watch([startDate, endDate], loadStats)
onBeforeMount(loadStats)

const extraStore = useCampaignExtraStore()

const { campaignInterpellationsStats } = storeToRefs(extraStore)

const expandedRowCampaignId = ref<string | null>(null)

const onRowClicked = async (index: number) => {
    if (!tableData.value) {
        throw new Error('Table data is not loaded')
    }
    const campaignId = tableData.value[index].cells.id.value.toString()
    if (expandedRowCampaignId.value === campaignId) {
        expandedRowCampaignId.value = null
    } else {
        expandedRowCampaignId.value = campaignId
        await extraStore.listCampaignInterpellationsStats(
            {
                created_start: startDate.value,
                created_end: endDate.value,
                page: 1,
            },
            campaignId
        )
    }
}
</script>
