import TRANSLATIONS_FR_USER from './translations/fr/user.json'
import TRANSLATIONS_FR_EMBED from './translations/fr/embed.json'
import TRANSLATIONS_EN_USER from './translations/en/user.json'
import TRANSLATIONS_EN_EMBED from './translations/en/embed.json'
import TRANSLATIONS_IT_USER from './translations/it/user.json'
import TRANSLATIONS_IT_EMBED from './translations/it/embed.json'
import TRANSLATIONS_SV_USER from './translations/sv/user.json'
import TRANSLATIONS_SV_EMBED from './translations/sv/embed.json'
import TRANSLATIONS_DE_USER from './translations/de/user.json'
import TRANSLATIONS_DE_EMBED from './translations/de/embed.json'
import TRANSLATIONS_PL_USER from './translations/pl/user.json'
import TRANSLATIONS_PL_EMBED from './translations/pl/embed.json'
import TRANSLATIONS_HR_USER from './translations/hr/user.json'
import TRANSLATIONS_HR_EMBED from './translations/hr/embed.json'
import TRANSLATIONS_PT_USER from './translations/pt/user.json'
import TRANSLATIONS_PT_EMBED from './translations/pt/embed.json'
import TRANSLATIONS_ES_USER from './translations/es/user.json'
import TRANSLATIONS_ES_EMBED from './translations/es/embed.json'

export const LOCALES_AVAILABLE = [
    'en',
    'fr',
    'de',
    'it',
    'sv',
    'pl',
    'hr',
    'pt',
    'es',
] as const

export type TranslationsSchema = {
    user: typeof TRANSLATIONS_EN_USER
    embed: typeof TRANSLATIONS_EN_EMBED
}

export const TRANSLATIONS: { [locale: string]: TranslationsSchema } = {
    fr: {
        user: TRANSLATIONS_FR_USER,
        embed: TRANSLATIONS_FR_EMBED,
    },
    en: {
        user: TRANSLATIONS_EN_USER,
        embed: TRANSLATIONS_EN_EMBED,
    },
    it: {
        user: TRANSLATIONS_IT_USER,
        embed: TRANSLATIONS_IT_EMBED,
    },
    sv: {
        user: TRANSLATIONS_SV_USER,
        embed: TRANSLATIONS_SV_EMBED,
    },
    de: {
        user: TRANSLATIONS_DE_USER,
        embed: TRANSLATIONS_DE_EMBED,
    },
    pl: {
        user: TRANSLATIONS_PL_USER,
        embed: TRANSLATIONS_PL_EMBED,
    },
    hr: {
        user: TRANSLATIONS_HR_USER,
        embed: TRANSLATIONS_HR_EMBED,
    },
    pt: {
        user: TRANSLATIONS_PT_USER,
        embed: TRANSLATIONS_PT_EMBED,
    },
    es: {
        user: TRANSLATIONS_ES_USER,
        embed: TRANSLATIONS_ES_EMBED,
    },
} as const
