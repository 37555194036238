<template>
    <div
        class="rounded-lg shadow-lg bg-white overflow-hidden flex flex-col items-center md:flex-row h-auto md:h-36"
    >
        <!-- Place Image -->
        <div class="hidden md:grid md:place-items-center h-full w-28">
            <img
                v-if="place.photo_url"
                :src="place.photo_url"
                :alt="props.place.displayName!"
                class="w-full h-full object-cover"
            />
            <img
                v-else
                :src="props.place.svgIconMaskURI!"
                :alt="props.place.displayName!"
                class="w-12 h-12 rounded-full p-2"
                :style="{
                    backgroundColor: props.place.iconBackgroundColor!,
                }"
            />
        </div>

        <!-- Content Container -->
        <div class="p-4 flex-1">
            <!-- Name and Rating -->
            <div class="mb-4">
                <h2 class="text-xl font-bold text-gray-800 mb-2">
                    {{ props.place.displayName }}
                </h2>
                <div
                    v-if="props.place.rating"
                    class="flex items-center space-x-2"
                >
                    <div class="flex">
                        <div v-for="i in 5" :key="i">
                            <svg
                                class="w-4 h-4"
                                :class="getStarClass(i)"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                />
                            </svg>
                        </div>
                    </div>
                    <span class="text-sm text-gray-600">
                        {{ props.place.rating }} ({{
                            props.place.userRatingCount
                        }}
                        reviews)
                    </span>
                </div>
            </div>

            <!-- Address -->
            <div class="flex items-start space-x-2 mb-3">
                <i class="fas fa-map-marker-alt text-gray-500 mt-1"></i>
                <span class="text-sm text-gray-600">{{
                    props.place.formattedAddress
                }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
interface Props {
    place: google.maps.places.Place & {
        photo_url: string | null
    }
}

const props = defineProps<Props>()

const getStarClass = (index: number): string =>
    index <= Math.floor(props.place.rating ?? 0)
        ? 'text-yellow-400'
        : 'text-gray-300'
</script>
