<template>
    <div class="flex flex-col">
        <div>
            <label
                :class="{
                    'inline mb-2 mr-1 text-[15px] font-medium lg:font-normal text-gray-900': true,
                    'text-red-700 ': errorTKey,
                }"
                v-if="label"
                :for="name"
                >{{ label }}</label
            >
            <AVInfoTooltip
                v-if="tooltipMessage"
                :id="name"
                :message="tooltipMessage"
            />
        </div>
        <div class="relative flex-1">
            <span
                v-if="prefix || prefixImg"
                class="text-neutral-400 absolute left-[0.5em] top-[50%] -translate-y-1/2"
            >
                <template v-if="prefix">
                    {{ prefix }}
                </template>
                <template v-else-if="prefixImg">
                    <i :class="`fi fi-${prefixImg}`"></i>
                </template>
            </span>

            <div
                class="border border-gray-300 text-gray-900 text-[13px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                :class="{
                    'is-focused': isFocused,
                    'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
                        errorTKey,
                }"
                @click="onFocus"
            >
                <span
                    v-for="tag in modelValue?.split(',').filter((tag) => !!tag)"
                    :key="tag"
                    class="bg-gray-100 text-gray-700 rounded-full px-2 py-1 mr-1"
                >
                    {{ tag }}
                    <span class="cursor-pointer" @click.stop="removeTag(tag)">
                        ×
                    </span>
                </span>
                <input
                    :id="name"
                    :name="name"
                    ref="inputRef"
                    type="text"
                    :placeholder="props.placeholder"
                    @keydown="onKeyDown"
                    class="original-input text-[13px]"
                    :style="{
                        'padding-left': prefix
                            ? `${prefix.length + 1}em`
                            : prefixImg
                              ? '2.3em'
                              : '',
                        display: type === 'color' ? 'none' : '',
                    }"
                    v-model="inputValue"
                    @blur="onBlur"
                    @focus="onFocus"
                />
            </div>
            <div
                :style="{
                    display: type === 'color' ? 'block' : 'none',
                    background: type === 'color' ? modelValue || 'white' : '',
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                }"
                @click="clickOnInput"
            ></div>
        </div>
        <AVError v-if="errorTKey" :error-t-key="errorTKey" />
    </div>
</template>

<script setup lang="ts">
import AVError from '@/components/forms/AVError.vue'
import AVInfoTooltip from '../Tooltip/AVInfoTooltip.vue'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { getCurrentInstance, ref } from 'vue'

interface Props extends FormFieldProps<string | null | undefined> {
    placeholder?: string
    type?: string
    prefix?: string
    prefixImg?: string
    tooltipMessage?: string
}

interface Emits extends FormFieldEmits<string> {
    (event: 'blur', domEvent: Event): void
    (event: 'focus', domEvent: Event): void
    (event: 'update:modelValue', value: string): void
}

const props = withDefaults(defineProps<Props>(), {
    type: 'text',
    placeholder: '',
    name: () => {
        const instance = getCurrentInstance()
        return 'input-'.concat(instance ? String(instance?.uid) : '')
    },
})

const emit = defineEmits<Emits>()

const isFocused = ref(false)

const onBlur = (event: Event) => {
    emit('blur', event)
    isFocused.value = false
}

const inputRef = ref<HTMLInputElement | null>(null)

const onFocus = (event: Event) => {
    emit('focus', event)
    isFocused.value = true
    inputRef.value?.focus()
}

const removeTag = (tagToRemove: string) => {
    emit(
        'update:modelValue',
        (props.modelValue || '')
            .split(',')
            .filter((tag) => tag !== tagToRemove)
            .join(',')
    )
}

const inputValue = ref('')

const addTag = (tag: string) => {
    const trimmedTag = tag.trim()
    if (
        trimmedTag &&
        !(props.modelValue || '').split(',').includes(trimmedTag)
    ) {
        if (props.modelValue === '') {
            emit('update:modelValue', trimmedTag)
        } else {
            emit('update:modelValue', [props.modelValue, trimmedTag].join(','))
        }
    }
    inputValue.value = ''
}

const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',') {
        e.preventDefault()
        addTag(inputValue.value.replace(/,/g, ''))
    } else if (
        e.key === 'Backspace' &&
        !inputValue.value &&
        (props.modelValue || '').split(',').length > 0
    ) {
        emit(
            'update:modelValue',
            (props.modelValue || '').split(',').slice(0, -1).join()
        )
    }
}

const clickOnInput = () => {
    document.getElementById(props.name)!.click()
}
</script>

<style scoped>
.original-input {
    border: none;
    outline: none;
    padding: 0.25rem;
    flex: 1;
    min-width: 120px;
}
.original-input:focus {
    border: none;
    box-shadow: none;
}
</style>
