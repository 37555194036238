import * as Yup from 'yup'
import { OrganizationWrite } from '@/models/organizations'
import { LlmProviderType } from '@/models/organizations'

export const organizationValidaton: Yup.ObjectSchema<OrganizationWrite> =
    Yup.object().shape({
        name: Yup.string().required(),
        is_tracking_sent_emails: Yup.boolean().required(),
        color_primary: Yup.string().required(),
        color_secondary: Yup.string().required(),
        color_accent: Yup.string().required(),
        logo: Yup.string().notRequired(),
        openai_api_key: Yup.string().optional().nullable(),
        mistral_api_key: Yup.string().optional().nullable(),
        anthropic_api_key: Yup.string().optional().nullable(),
        gemini_api_key: Yup.string().optional().nullable(),
        deepseek_api_key: Yup.string().optional().nullable(),
        llm_provider: Yup.mixed<LlmProviderType>().optional(),
        llm_model: Yup.string().optional(),
    })
